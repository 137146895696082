import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Box } from "@material-ui/core";


function ButtonCircularProgress({ size, color}) {
  return (
    <Box color="secondary.main" pl={1.5} display="flex">
      <CircularProgress
        size={size ? size : 24}
        thickness={size ? (size / 5) * 24 : 5}
        style={{color: color}}
      />
    </Box>
  );
}

ButtonCircularProgress.propTypes = {
  size: PropTypes.number,
};

export default ButtonCircularProgress;
