import { toast } from 'react-toastify';
import smoothScrollTop from 'shared/functions/smoothScrollTop'
import Cookies from 'js-cookie'


const mainReducer = (state, action) => {
    switch (action.type) {
        case 'changePageTitle':
            state.setSelectedTab(`${action.title}`);
            document.title = `${action.title} | Dojo Payroll`
            smoothScrollTop()
            return state;
    
        case 'notification':
            notification(action.level, action.message)
            return state;

        case 'shuffle':
            shuffle(action.array)
            return state;

        case 'add to state':
            return {...state, ...action};

        case 'wrangle data':
            return wrangleData({obj: action.data});

        case 'rename':
            return renameDeductions(action.arr);

        case 'set-cookie':
			const {name, cookie, expires} = action
			// mainReducer('state', { type: 'set-cookie', name: 'questionnaire', cookie: clean.cartUid, expires: 1 })
            Cookies.set(name, cookie, { expires })
			break;

        case 'get-cookie':
			// mainReducer('state', { type: 'get-cookie', name: 'questionnaire'})
            return Cookies.get(`${action.name}`);

        case 'remove-cookie':
			// mainReducer('state', { type: 'remove-cookie', name: 'questionnaire'})
            Cookies.remove(`${action.name}`)
			break;
    
        case 'companyId':
			return {...state, companyId: action.id};
    
        case "camelizedTaxSettings":
			const camelized = toCamel(action.taxSettings)
			const items = ['preTax', 'postTax', 'bonus']
			items.forEach(item => camelized[item] = camelized[item] || [{name: "", rate: "", apply_to: "" }] )
			return camelized
    
        case 'updateQuery':
			console.log(action.data);
			break;
    
        default:
            return state;
    }
}

const renameDeductions = (arr)=>{
	let data = {};
	if(arr.length){
		for (const [index, obj] of arr.entries())
		Object.entries(obj).reduce((accumulator, [key, value]) =>{
			data[`${key}${index}`] = value
			return accumulator
		}, {})
	}
	return data
}

export const wrangleData = (props)=>{
	const {obj, infoIsFilled, setVal} = props;
	const cleanData = {}
	try {
		Object.entries(obj).forEach(([key, value]) => {
			if(typeof(value) === 'string' && value.includes('{')){
				cleanData[key]=JSON.parse(obj[key])
			}else{
				cleanData[key]=obj[key]
			}
		});
	} catch (error) {
		console.log(error.message);
		return error
	}
	const flat = flattenFunc(cleanData)
	
	if(typeof(infoIsFilled) === 'function') infoIsFilled(true)
	if(typeof(setVal) === 'function') setVal(flat)
	return flat
}

const flattenFunc = (obj) => {
	
	const flattened = {}
  
	Object.entries(obj).forEach(([key, value]) => {
		if (value instanceof Object ) {
			Object.assign(flattened, flattenFunc(value))
		} else {
			flattened[key] = value
		}
	})
	const data = toCamel(flattened)
	
	return data
}
// This replaces underscore or spaces with camelCase

export const toCamel = (obj) => { 
	const _toCamel = s => s.replace(/(_\w|\s\w)/g, k => k[1].toUpperCase())
	let data = Object.entries(obj).reduce((accumulator, [key, value]) =>{
		return { ...accumulator, [_toCamel(key)]: value }
	}, {})

	return data
}


export const notification = (type, message) => {
	toast[type](message, {
		position: "top-left",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "colored",
	});
}

  


export const jwt_encoder = () => {
	var crypto = require("crypto");
	// payload, key, algorithm, options
	const payload = {
		str: Math.random().toString(36).substring(2),
		exp: new Date().getTime(),
	};
	// Must change it in production
	const secret = "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";

	/* support algorithm mapping */
	const algorithmMap = {
		HS256: "sha256",
		HS384: "sha384",
		HS512: "sha512",
		RS256: "RSA-SHA256",
	};

	/* Map algorithm to hmac or sign type, to determine which crypto function to use */
	const typeMap = {
		HS256: "hmac",
		HS384: "hmac",
		HS512: "hmac",
		RS256: "sign",
	};

	const sign = (input, key, method, type) => {
		var base64str;
		if (type === "hmac") {
			base64str = crypto.createHmac(method, key).update(input).digest("base64");
		} else if (type === "sign") {
			base64str = crypto.createSign(method).update(input).sign(key, "base64");
		} else {
			throw new Error("Algorithm type not recognized");
		}

		return base64urlEscape(base64str);
	};

	const base64urlEncode = (str) => {
		return base64urlEscape(Buffer.from(str).toString("base64"));
	};

	const base64urlEscape = (str) => {
		return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
	};

	const algorithm = "HS256";
	const signingMethod = algorithmMap[algorithm];
	const signingType = typeMap[algorithm];

	// header, typ is fixed value.
	const header = { typ: "JWT", alg: algorithm };

	// create segments, all segments should be base64 string
	const segments = [];
	segments.push(base64urlEncode(JSON.stringify(header)));
	segments.push(base64urlEncode(JSON.stringify(payload)));
	segments.push(sign(segments.join("."), secret, signingMethod, signingType));

	return segments.join(".");
};

export const futureDate = (days) =>{
	const today = new Date();
	const dt = new Date(
	  today.getFullYear(), 
	  today.getMonth(), 
	  today.getDate()+days
	);
	return dt
}


export function shuffle(array) {
	for (let i = array.length - 1; i > 0; i--) {
	  let j = Math.floor(Math.random() * (i + 1));
	  [array[i], array[j]] = [array[j], array[i]];
	}
}

export const people = [
	{
	  src: `${process.env.PUBLIC_URL}/images/logged_in/image1.jpg`,
	  name: "Markus",
	},
	{
	  src: `${process.env.PUBLIC_URL}/images/logged_in/image2.jpg`,
	  name: "David",
	},
	{
	  src: `${process.env.PUBLIC_URL}/images/logged_in/image3.jpg`,
	  name: "Arold",
	},
	{
	  src: `${process.env.PUBLIC_URL}/images/logged_in/image4.jpg`,
	  name: "Joanic",
	},
	{
	  src: `${process.env.PUBLIC_URL}/images/logged_in/image5.jpg`,
	  name: "Sophia",
	},
	{
	  src: `${process.env.PUBLIC_URL}/images/logged_in/image6.jpg`,
	  name: "Aaron",
	},
	{
	  src: `${process.env.PUBLIC_URL}/images/logged_in/image7.jpg`,
	  name: "Steven",
	},
	{
	  src: `${process.env.PUBLIC_URL}/images/logged_in/image8.jpg`,
	  name: "Felix",
	},
	{
	  src: `${process.env.PUBLIC_URL}/images/logged_in/image9.jpg`,
	  name: "Vivien",
	},
	{
	  src: `${process.env.PUBLIC_URL}/images/logged_in/image10.jpg`,
	  name: "Leonie",
	},
  ];
  
  export default mainReducer;