import ReactDOM from "react-dom";
import App from "./App";
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

// import { RetryLink } from "@apollo/client/link/retry";

// const retryLink = new RetryLink();

const {REACT_APP_API} = process.env
// let REACT_APP_API;

// if (window.location.origin === "http://localhost:3000") {
//   REACT_APP_API = "http://127.0.0.1:8000/v1/";
// } else {
//   REACT_APP_API = window.location.origin;
// }

const httpLink = new HttpLink({
  uri: REACT_APP_API
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors){
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    )}

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// If you provide a link chain to ApolloClient, you
// don't provide the `uri` option.
export const client = new ApolloClient({
  // The `from` function combines an array of individual links
  // into a link chain
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      CompanyType: {
        keyFields: ["email"],
      },
      Employees: {
        keyFields: ["slug"],
      },
    }
  })
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);


