import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';



const useStyles = makeStyles((theme) => ({
  
  wrapper: {
    margin: theme.spacing(1),
  flexGrow: 1,
  alignItems: "center",
  align: "center",
    width: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      width: "82.5%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
  },
}));


export const QueryLoader = () =>{
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <Skeleton variant="text" animation="wave" width='70%' height={50} />
      <Skeleton variant="circle" animation="wave" width={60} height={60} />
      <Skeleton variant="rect" animation="wave" width='80%' height={100} />
      <Skeleton variant="text" animation="wave" width='75%' height={70} />
      <Skeleton variant="rect" animation="wave" width='80%' height={100} />
    </div>
  );
}

export const QueryError = ({error}) =>{
  const classes = useStyles()
  return (
    <div style={{ color: 'red' }} className={classes.wrapper}>
    <h3>Error: {error}</h3> 
      <Skeleton variant="text" animation="wave" width='70%' height={50} />
      <Skeleton variant="circle" animation="wave" width={60} height={60} />
      <Skeleton variant="rect" animation="wave" width='80%' height={100} />
      <Skeleton variant="text" animation="wave" width='75%' height={70} />
      <Skeleton variant="rect" animation="wave" width='80%' height={100} />
    </div>
  );
}
