import React, { useState, useEffect } from "react";
// import { useField } from "formik";
import {
	TextField,
	Select,
	Checkbox,
	Grid,
	InputLabel,
	MenuItem,
	FormControl,
	FormControlLabel,
	withStyles,
	Switch,
	FormGroup,
	IconButton,
	InputAdornment,
} from "@material-ui/core";
import { Field, ErrorMessage, useField } from "formik";
import TextError from "./TextError";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export const InputField = (props) => {
	const { label, name, ...rest } = props;

	return (
		<div>
			<Field
				as={TextField}
				id={name}
				name={name}
				{...rest}
				label={label}
				variant="outlined"
			/>
			<ErrorMessage name={name} component={TextError} />
		</div>
	);
};

export const PasswordField = (props) => {
	const { label, name, ...rest } = props;
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	return (
		<div>
			<Field
				as={TextField}
				id={name}
				name={name}
				type={showPassword ? "text" : "password"}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
								{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton>
						</InputAdornment>
					),
				}}
				{...rest}
				label={label}
				variant="outlined"
				// size="small"
			/>
			<ErrorMessage name={name} component={TextError} />
		</div>
	);
};
export const SSNField = (props) => {
	const { label, name, ...rest } = props;
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	return (
		<div>
			<Field
				as={TextField}
				id={name}
				name={name}
				type={showPassword ? "text" : "password"}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
								{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton>
						</InputAdornment>
					),
				}}
				{...rest}
				label={label}
				variant="outlined"
				// size="small"
			/>
			<ErrorMessage name={name} component={TextError} />
		</div>
	);
};

export const SelectField = (props) => {
	const { label, name, options, variant, minWidth, ...rest } = props;
	const [field] = useField(props);
	const { value: selectedValue } = field;

	return (
		<div>
			<FormControl
				variant={variant ?? "filled"}
				style={{
					minWidth: minWidth || 150,
					
				}}
			>
				<InputLabel>{label}</InputLabel>

				<Field as={Select} id={name} name={name} {...field} {...rest} value={selectedValue ? selectedValue : ""}>
					<MenuItem value="">...</MenuItem>
					{options.map((item) => (
						<MenuItem key={item.key} value={item.key}>
							{item.value}
						</MenuItem>
					))}
				</Field>
			</FormControl>
			<ErrorMessage name={name} component={TextError} />
		</div>
	);
};

export const CheckboxGroup = (props) => {
	const { label, name, options, ...rest } = props;
	return (
		<div>
			<Field
				as={FormControlLabel}
				control={<Checkbox />}
				name={name}
				type="checkbox"
				label={label}
				{...rest}
				//
			/>
			<ErrorMessage component={TextError} name={name} />
		</div>
	);
};


export const MUIDatePicker = (props) => {
	const { name, label, value, setFieldValue, ...rest } = props;
	const [date, setDate] = React.useState(new Date(new Date().getFullYear(), 11, 31));

	const changeDate = (date) => {
		if (date) {
			setDate(date);
		} else {
			setDate(null);
		}
	};

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Grid container>
				<Field
					as={KeyboardDatePicker}
					id="date-picker-dialog"
					label="Date picker dialog"
					disableToolbar
					inputVariant="outlined"
					format="MM/dd/yyyy"
					value={date}
					autoOk
					selected={date}
					{...rest}
					onChange={changeDate}
					KeyboardButtonProps={{
						"aria-label": "change date",
					}}
				/>
			</Grid>
		</MuiPickersUtilsProvider>
	);
};

export function DatePickerField(props) {
	const [field, meta, helper] = useField(props);
	const { touched, error } = meta;
	const { setValue } = helper;
	const isError = touched && error && true;
	const { value } = field;
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (value) {
			const date = new Date(value);
			const lstring = date.toLocaleString().split(",")[0];
			setSelectedDate(lstring);
		}
	}, [value]);

	function _onChange(date) {
		if (date) {
			try {
				const lstring = date.toLocaleString().split(",")[0];
				setSelectedDate(lstring);
				setValue(new Date(date));
			} catch (error) {
				setValue(date);
			}
		} else {
			setValue(date);
		}
	}

	return (
		<Grid container>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					TextFieldComponent={(props) => <TextField {...props} onClick={(e) => setOpen(true)} />}
					{...field}
					{...props}
					//
					// disableToolbar
					inputVariant="outlined"
					value={selectedDate}
					onChange={_onChange}
					error={isError}
					autoOk
					invalidDateMessage={isError && error}
					helperText={isError && error}
				/>
			</MuiPickersUtilsProvider>
		</Grid>
	);
}

const IOSSwitch = withStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 1,
		"&$checked": {
			transform: "translateX(16px)",
			color: theme.palette.common.white,
			"& + $track": {
				backgroundColor: "#0ad33c",
				opacity: 1,
				border: "none",
			},
		},
		"&$focusVisible $thumb": {
			color: "#0ad33c",
			border: "6px solid #fff",
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(["background-color", "border"]),
	},
	checked: {},
	focusVisible: {},
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});


export const IosSwitch = (props) => {
	const { name, label, ...rest } = props;
	const [field, meta, helper] = useField(props);
	const { touched, error } = meta;
	const isError = touched && error && true;
	const { setValue } = helper;
	const { value } = field; // eslint-disable-line

	const [state, setState] = useState();
	const handleChange = (event) => {
		setState(!state);
		if (event) {
			try {
				setValue(!state);
			} catch (error) {
				setValue(event);
			}
		} else {
			setValue(event);
		}
	};

	return (
		<FormGroup>
			<FormControlLabel
				control={<IOSSwitch checked={state} onChange={handleChange} name={name} />}
				label={label}
				required={isError}
				// disabled={trueOrFalse}
				{...rest}
				//
			/>
		</FormGroup>
	);
};



