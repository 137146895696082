import React from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ButtonCircularProgress from "shared/components/ButtonCircularProgress";
import { confirmReset } from "./Config";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PasswordField } from "logged_in/components/companyForms/Fields.js";
import classNames from 'classnames';
import { confirmPath } from "logged_out/components/Routing"


const useStyles = makeStyles((theme) =>({
    root: {
      width: "100%",
      marginBottom: "2rem",
      margin: "auto",
      align: "center",
      [theme.breakpoints.down("md")]: {
        maxWidth: "80%"
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "95%"
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "950%"
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "30%",

      },
    },
  }));

function ResetPassword(props) {
    const { oobCode } = props
    
    const classes = useStyles();

	const initialValues = {
		password: "",
		confirmPassword: "",
	};
    
	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.required("Required.")
			.test("len", "Must be at least 8 characters long", (val) => val && val.length >= 8),
		confirmPassword: Yup.string()
			.required("Please confirm your password")
			.oneOf([Yup.ref("password"), null], "Passwords does not match"),
	});

	const onSubmit = async (values, props) => {
    const { password } = values;
	const { setSubmitting, setFieldError } = props;
    await confirmReset(oobCode, password)
    .then(() => {
        setSubmitting(false)
        window.location.replace(confirmPath)
    })
    .catch((error) => {
        setFieldError("password", error);
        setSubmitting(false)
    });
	};


  return (
    <Grid className={classNames(classes.root, "container-fluid", "lg-mg-top", "lg-mg-bottom")}>
        <Typography variant="h6">
          Enter a new password.
        </Typography>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {(props) => (
            <Form>
                <PasswordField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Password"
                    name="password"
                    autoComplete="on"
                />
                <PasswordField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Password"
                    name="confirmPassword"
                    autoComplete="on"
                />
              <div align="center">
                <Button
                  style={{marginBottom:'1rem', marginTop:'1rem', }}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={props.isSubmitting}
                  fullWidth
                  size="large"
                >
                  {props.isSubmitting ? <ButtonCircularProgress /> : "Change Password"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
    </Grid>
  );
}


export default ResetPassword;
