import * as React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import FacebookIcon from '@material-ui/icons/Facebook';
import TelegramIcon from '@material-ui/icons/Telegram';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CloseIcon from '@material-ui/icons/Close';
import {
  Table, 
  withStyles,
  makeStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, 
  Card, 
  Box, 
  Typography,
} from '@material-ui/core';
import { faq } from 'logged_out/components/home/Data';
import FAQ from "shared/components/FAQ"


const useStyles = makeStyles((theme) =>({
  table: {
    width: "100%",
    marginBottom: "2rem",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%"
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "95%"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95%"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "70%",
    },
  },
}));


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#5F4DEF",
    color: theme.palette.common.white,
    fontSize: 25,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const createData = (feature, Dojo, Gusto) =>{
  return { feature, Dojo, Gusto};
}

const Icon = ()=><CheckIcon style={{color: '#5f4def'}} />

const dojoSupport = [<PhoneInTalkIcon />, <MailOutlineIcon />, <FacebookIcon />, <TelegramIcon />]
const gustoSupport = [<PhoneInTalkIcon />, <MailOutlineIcon />]
const headings = ["Feature", "Dojo", "Gusto"]
const rows = [
  createData('Pricing', "Per employee only", "Base fee plus per employee"),
  createData('Price', "$5.99/employee", "$39 plus $6/employee or $12/employee"),
  createData('Next-day direct deposit', "Free", "$12/employee plan"),
  createData('Time tracking', "Included", "$12/employee plan"),
  createData('Customer support', [...dojoSupport], [...gustoSupport]),
  createData('Custom onboarding', "Just send us W-4s", "Do it yourself"),
  createData('HR', <CloseIcon style={{color: '#5f4def'}}/>, Icon()),
  createData('Unlimited payroll', Icon(), Icon()),
  createData('W-2s', Icon(), Icon()),
  createData('1099s', Icon(), Icon()),
  createData('Garnishments', Icon(), Icon()),
  createData('Multi-state payroll', Icon(), Icon()),
  createData('Tax filings and payments', Icon(), Icon()),
  createData('Multiple pay rates and schedules', Icon(), Icon()),
];


const CustomizedTables = () =>{
  const classes = useStyles();
  
  
  return (
    <Box 
      flexDirection="column"
      id="compare"
      justifyContent="center" 
    >

      <Typography variant="h3" align="center" color="textPrimary">
        Compare Us To Gusto
      </Typography>
      <Card
        data-aos-delay="200"
        data-aos="zoom-in"
      >
        <TableContainer component={Paper} >
          <Table className={classes.table} >
            <TableHead>
              <TableRow>
                {headings.map(elm =>(
                  <StyledTableCell key={elm}>{elm}</StyledTableCell>
                )) }
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.feature}>
                  <StyledTableCell component="th" scope="row">{row.feature}</StyledTableCell>
                  <StyledTableCell>{row.Dojo}</StyledTableCell>
                  <StyledTableCell>{row.Gusto}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <FAQ arr={faq} />
    </Box>
    
  );
}

export default CustomizedTables;
