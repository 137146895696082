import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { mainContext } from "logged_in/components/Main";



export const PrivateRoute = ({ component, ...rest }) => {
  const {mainState, mainDispatch} = useContext(mainContext);
  const {currentUser} = mainState
  if(!currentUser){
    mainDispatch({type: 'notification', level: 'error', message: "You are not logged in"})
  }

  return (
    <Route {...rest} render={routeProps => {
      return currentUser  ? (
        renderMergedProps(component, routeProps, rest)
      ) : (
        <Redirect to={{
          pathname: "/",
          state: { from: routeProps.location }
        }}/>
      );
    }}/>
  );
};


const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

/**
 * Wrapper around the Router component, which makes it pass the properties
 * to it's child.
 * Taken from https://github.com/ReactTraining/react-router/issues/4105
 */
export const PropsRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={routeProps => renderMergedProps(component, routeProps, rest)}
  />
);


PropsRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node])
};

