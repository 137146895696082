
import React, { useContext, useEffect } from "react";
import { makeStyles, Card, CardActionArea, CardMedia } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { mainContext } from "logged_in/components/Main";

const useStyles = makeStyles({
	root: {
		minWidth: "90vw",
	
	},
	media: {
		minHeight: "90vh",
		minWidth: "100%",
		backgroundPosition: "bottom right",
		position: "relative",
		height: "calc(100vh - 80px)",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	},
});

export default function MediaCard(props) {
	const classes = useStyles();

	const {mainDispatch} = useContext(mainContext);

	useEffect(() => {
		mainDispatch({type: 'changePageTitle', title: '404'})
	}, [mainDispatch])
	return (
		<Card className={classes.root}>
			<Helmet>
				<title>404 Error</title>
				<meta name="description" content="Add A New Employee" />
			</Helmet>
			<CardActionArea>
				<a href="/d/dashboard">
					<CardMedia 
						className={classes.media} 
						image={`${process.env.PUBLIC_URL}/assets/404.jpg`} 
						title="Click anywhere for Home Page" 
					/>
				</a>
			</CardActionArea>
		</Card>
	);
}
