import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Hero from "./Hero";
import FeatureSection from "./FeatureSection";
import Pricing from "./Pricing";
import Compare from "./Compare"
import { headSection } from "./Data"



const Home = (props)=> {
  const { selectHome, checkQuestionnaire, size } = props;
  useEffect(() => {
    selectHome();
  }, [selectHome]);


  return (
    <Fragment>
      <Hero 
        {...headSection }
        checkQuestionnaire={checkQuestionnaire}
        size={size}
      />
      <FeatureSection />
      <Pricing 
        checkQuestionnaire={checkQuestionnaire}
      />
      <Compare />
    </Fragment>
  );
}

Home.propTypes = {
  selectHome: PropTypes.func.isRequired
};

export default Home;
