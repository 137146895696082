import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Typography, withStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const styles = theme => ({
  iconWrapper: {
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
    display: "inline-flex",
    align: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1) * 1.5
  },
  CheckIcon: {
    color: theme.palette.primary.main
  },
  textColor:{
    // color: 'white',
    fontSize: "1.2em",
    fontWeight: 'bold',
  }
});


function FeatureCard(props) {
  const { classes, Icon, headline, text, list } = props;
  return (
    <Fragment>
      <Box 
        // className={classes.iconWrapper}
        align='center'
      >
        {Icon}
      </Box>
      <Typography variant="h4" paragraph align='center' style={{fontWeight: 'bold'}}>
        {headline}
      </Typography>
      <Typography variant="body1" color="textSecondary" className={classes.textColor}>
        {text}
      </Typography>
      
      {Array.isArray(list) && list.map(item => 
        <Box display="flex" alignItems="center" mb={1} key={item}>
          <CheckIcon
            className={classes.CheckIcon}
          />
          <Box ml={1}>
            <Typography variant="body1" color="textSecondary" className={classes.textColor}>
              {item}
            </Typography>
          </Box>
        </Box>
        )}
    </Fragment>
  );
}

FeatureCard.propTypes = {
  classes: PropTypes.object.isRequired,
  Icon: PropTypes.element.isRequired,
  headline: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired
};

export default withStyles(styles, { withTheme: true })(FeatureCard);
