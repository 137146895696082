import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import { verifyCode, completeVerification, logout } from './Config';



const useStyles = makeStyles((theme) =>({
    root: {
      width: "100%",
      marginBottom: "2rem",
      margin: "auto",
      [theme.breakpoints.down("md")]: {
        maxWidth: "90%"
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%"
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "70%",

      },
    },
  }));


const ConfirmationPage = (props) => {
  const classes = useStyles()

  const { selectConfirmationPage } = props

  const [mode, setMode] = useState("")
  const [oobCode, setOobCode] = useState("")

  const urlParams = useLocation().search
  

  useEffect(() => {

    selectConfirmationPage();
    const query = new URLSearchParams(urlParams)

    if(query.get("mode") === "resetPassword"){
      verifyCode(query.get("oobCode"))
      .then(() => {
        setOobCode(query.get("oobCode"))
        setMode(query.get("mode"))
      })
      .catch((error) => {
        setMode("wrongCode")
      });
    }
    if(query.get("mode") === "verifyEmail"){
      verifyCode(query.get("oobCode"))
      .then(() => {
        completeVerification(query.get("oobCode"))
        setOobCode(query.get("oobCode"))
        setMode(query.get("mode"))
      })
      .catch((error) => {
        setMode("wrongCode")
      });
    }

    logout()

  }, [selectConfirmationPage, urlParams]);
  

  switch (mode) {
    case "resetPassword":
      return (
        <ResetPassword oobCode={oobCode} />
      );
    case "wrongCode":
      return (
        <Fragment>
            <Grid className={classNames(classes.root, "container-fluid", "lg-mg-top", "lg-mg-bottom")}>
                <Box>
                <Grid align="center" >
                    <img 
                        alt="sent" 
                        src={`${process.env.PUBLIC_URL}/assets/dog.gif`}
                        style={{ height: "40vh", width: "20vw" }}
                    />
                    <h1>Woof! Woof!</h1>
                    <h2 style={{color: "red"}} >Your link has expired </h2>
                </Grid>
                </Box>
            </Grid>
        </Fragment>
      );
    default:
      return (
        <Fragment>
            <Grid className={classNames(classes.root, "container-fluid", "lg-mg-top", "lg-mg-bottom")}>
                <Box>
                <Grid align="center" >
                    <img 
                        alt="sent" 
                        src={`${process.env.PUBLIC_URL}/assets/ballons.gif`}
                        style={{ height: "40vh", width: "70vw" }}
                    />
                    <h1>Hurray!</h1>
                    <h3>You can now log in</h3>
                </Grid>
                </Box>
            </Grid>
        </Fragment>
      );
  }
    
}

export default ConfirmationPage
