import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Toolbar,
  ListItemIcon,
  Box,
  withStyles,
} from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";

const styles = theme => ({
  paper: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  toolbar: { justifyContent: "space-between" },
  scaleMinus: {
    transform: "scaleX(-1)"
  },
  "@keyframes spin": {
    from: { transform: "rotate(359deg)" },
    to: { transform: "rotate(0deg)" }
  },
  spin: { animation: "$spin 2s infinite linear" },
  listItemSecondaryAction: { paddingRight: theme.spacing(1) }
});

function AccountInformationArea(props) {
  const { classes, isAccountActivated } = props;
  return (
    // <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <ListItemIcon>
            <div className="tooltip">
              <LoopIcon
                className={classNames(
                  isAccountActivated ? classes.spin : null,
                  classes.scaleMinus
                )}
              />
              <span className="tooltiptext">
                {isAccountActivated ? "Your account is active" : "Your account is not active, verify your email"}
              </span>
            </div>
          </ListItemIcon>
        </Box>
        {/* <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <Switch
            color="secondary"
            checked={isAccountActivated}
            onClick={toggleAccountActivation}
            inputProps={{
              "aria-label": isAccountActivated
                ? "Deactivate Account"
                : "Activate Account"
            }}
          />
        </ListItemSecondaryAction> */}
      </Toolbar>
    
  );
}

AccountInformationArea.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isAccountActivated: PropTypes.bool.isRequired
};

export default withStyles(styles, { withTheme: true })(AccountInformationArea);
