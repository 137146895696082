import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import {Typography, Button, Box} from '@material-ui/core';
import classNames from 'classnames';



const Accordion = withStyles((theme)=>({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    flexDirection: "column",
    align: "center",
    justifyContent: "center",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%"
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "95%"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95%"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "70%",
    },
  
  },
  expanded: {},
}))(MuiAccordion);


const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function CustomizedAccordions({arr}) {

  const [showFaq, setShowFaq] = React.useState(false)

  const handleClick = ()=>{setShowFaq(!showFaq)}
  

  return (
    <Box className={classNames("lg-p-top lg-mg-bottom")}>
      <Typography variant="h3" align="center" color="textPrimary"  >
          FAQs
      </Typography>
      {showFaq && (
        arr.map(elm =>(
            <Accordion 
              square
              key={elm.heading}
            >
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography>{elm.heading}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{elm.details}</Typography>
              </AccordionDetails>
            </Accordion>
        ))
      )
      }
      <Box textAlign='center' mt={2}>
      <Button 
        variant='outlined' 
        onClick={handleClick} 
        style={{ margin: "auto", justifyContent: 'center', textTransform: "none" }}
      >
        {showFaq ? "Hide FAQs" : "Show FAQs"}
      </Button>
    </Box>
    </Box>
  );
}
