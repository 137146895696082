import { lazy, memo } from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import {PrivateRoute} from "shared/components/PropsRoute";
import useLocationBlocker from "shared/functions/useLocationBlocker";
import Error from "shared/components/404";
import {QueryLoader, QueryError} from "shared/components/SkeletonLoader";


const componentMap = Object.freeze({
	DASHBOARD: lazy(() => import("./dashboard/Dashboard")),
	SUBSCRIPTIONS: lazy(() => import("./subscription/Subscription")),
	SETTINGS: lazy(() => import("logged_in/components/dashboard/Settings")),
	UPDATE_EMPLOYEE: lazy(() => import("logged_in/components/companyForms/updateEmployee/EmployeeUpdate")),
	COMPANY_PROFILE: lazy(() => import("logged_in/components/companyForms/CompanyProfile")),
	CREATE_COMPANY: lazy(() => import("logged_in/components/companyForms/createCompany")),
	AUTH: lazy(() => import("./Auth")),
	ADD_EMPLOYEE: lazy(() => import("logged_in/components/companyForms/addEmployee")),
	ADD_EMPLOYEE2: lazy(() => import("logged_in/components/companyForms/addEmployee/MobileStepper")),
});

function Routing(props) {

  const { openAddBalanceDialog, size } = props;
  
  useLocationBlocker();
  return (
    <Switch>
      <PrivateRoute
        path="/d/dashboard"
        component={componentMap["DASHBOARD"]}
        openAddBalanceDialog={openAddBalanceDialog}
        QueryLoader={QueryLoader}
        QueryError={QueryError}
      />
      <PrivateRoute
        path="/d/settings"
        component={componentMap["SETTINGS"]}
        size={size}
        QueryLoader={QueryLoader}
        QueryError={QueryError}
      />
      <PrivateRoute
        // path="/d/create-company"
        path="/d/create-company/token=:id"
        component={componentMap["CREATE_COMPANY"]}
        size={size}
        QueryLoader={QueryLoader}
        QueryError={QueryError}
      />
      <PrivateRoute
        path="/d/company"
        component={componentMap["COMPANY_PROFILE"]}
        size={size}
        QueryLoader={QueryLoader}
        QueryError={QueryError}
      />
      <PrivateRoute
        path="/d/employee/:id"
        component={componentMap["UPDATE_EMPLOYEE"]}
        size={size}
        QueryLoader={QueryLoader}
        QueryError={QueryError}
      />
      <PrivateRoute
        path="/d/add-employee"
        component={componentMap["ADD_EMPLOYEE"]}
        size={size}
        QueryLoader={QueryLoader}
        QueryError={QueryError}
      />
      <PrivateRoute 
        path="/d/auth/:id" 
        component={componentMap["AUTH"]} 
      />
      <PrivateRoute
        path="/d"
        component={Error}
      />
    </Switch>
  );
}

Routing.propTypes = {
  openAddBalanceDialog: PropTypes.func.isRequired,
};

export default (memo(Routing));
