


const IMAGES = process.env.PUBLIC_URL


export const headSection = {
  topline: 'Just a glorified calculator',
  headline: 'Run Your Business On Automatic Payroll',
  descriptions: ["Automate Payroll", "Save time & money", "Automatic Compliance", "Eliminate Human Errors"],
  buttonLabel: '2 Months Free Trial',
  imgage: `${IMAGES}/assets/hero.svg`,
  alt: 'hero image',
};

const iconSize = "5em"

export const features = [
  {
    headline: "Cloud Time Tracking",
    text:
      "Frictionless time tracking on employees' phones or on a computer at work.",
    icon: <img src={`${IMAGES}/assets/tracking.svg`} alt="tracking" style={{ width: iconSize, }} />,
    list: ["No expensive hardware to buy,", "Run everything in the cloud,", "Know your gross wage in real time."],
    mdDelay: "0",
    smDelay: "0"
  },
  {
    headline: "Unlimited Payroll",
    text:
      "Modern payroll system that you have control over.",
    icon: <img src={`${IMAGES}/assets/unlimited.svg`} alt="unlimited payroll" style={{ width: iconSize, }} />,
    list: ["Run payroll unlimited number of times,", "Synch all your employees to one pay day or,", "Set separate dates for each employee."],
    mdDelay: "0",
    smDelay: "0"
  },
  {
    headline: "Pre-Tax Deductions",
    text:
      "Regardless of your company size, you can now help your employees save money, tax-free! Available pre-tax deductions includes",
    icon: <img src={`${IMAGES}/assets/pretax.svg`} alt="Pre-Tax" style={{ width: iconSize, }} />,
    list: ["401k & IRA accounts,","Health & dental Insurance,", "Child Care Expenses, and more."],
    mdDelay: "200",
    smDelay: "200"
  },
  {
    headline: "Garnishments",
    text:
      "Should your company be ordered to withhold garnishments from employees, the rules can get complicated.",
    icon: <img src={`${IMAGES}/assets/garnishments.svg`} alt="Garnishments" style={{ width: iconSize, }} />,
    list: ["25% or 30 times the minimum wage.", "Good news is, it's all taken care of for you."],
    mdDelay: "200",
    smDelay: "200",
  },
  {
    headline: "Quarterly Reports",
    text:
      "You will get quarterly tax reports by email so that you have complete overview of the standing your company with the federal and state authorities.",
    icon: <img src={`${IMAGES}/assets/quarterly.svg`} alt="Quarterly Reports" style={{ width: iconSize, }} />,
    list: ["Breakdown of taxes into Federal, State and local,", "Employee cost analysis,", "Helps you make better hiring decisions."],
    mdDelay: "200",
    smDelay: "200",
  },
  {
    headline: "Overtime Pay",
    text:
      "A lot of businesses loss money on overtime. For many businesses, they either provide overtime pay for all employees or none at all.",
    icon: <img src={`${IMAGES}/assets/overtime.svg`} alt="Overtime." style={{ width: iconSize, }} />,
    list: ["The Software will qualify employees for overtime,", "Be compliant at all times,", "Saves you money."],
    mdDelay: "200",
    smDelay: "200",
  },
  {
    headline: "Tax Filing (Optional)",
    text:
      "We file quarterly federal and state tax forms on behalf of our client.",
    icon: <img src={`${IMAGES}/assets/tax.svg`} alt="Filing." style={{ width: iconSize, }} />,
    list: ["We file form W-2, 940, 941, 1099, State & local,", "This is completely OPTIONAL,", "Paid quarterly."],
    mdDelay: "200",
    smDelay: "200",
  },
  {
    headline: "Best Tool For HR",
    text:
      "This software is a tool for HR not to replace it. We have taken it even a step further, HR agencies, accountants and bookkeepers can manage multiple companies at 50% discount, in perpetuity. You only need:",
    icon: <img src={`${IMAGES}/assets/pros.svg`} alt="hr." style={{ width: iconSize, }} />,
    list: ["Minimum of 3 active companies with,", "Minimum of 11 employees (combined)."],
    mdDelay: "200",
    smDelay: "200",
  },
];



export const pricing = [
  {
    id:1,
    icon: <img src={`${IMAGES}/assets/payroll.svg`} alt="payroll." style={{ width: "40%", }} />, 
    desc: "Traditional subscriptions services like Gusto charge a base fee between $39 and $149 plus $12 per employee. We are giving you a value base service. That means you will only be charged on usage basis following this straight forward equation:",
    equation: "Number of employees that used the software in a month x ",
    plan: { monthly: "$5.99", yearly: "$3.99", type: "Montly" },
    mdDelay: "0",
    smDelay: "0"
  },
  {
    id:2,
    icon: <img src={`${IMAGES}/assets/filing.svg`} alt="payroll." style={{ width: "40%", }} />, 
    desc:"Since we only charge when we provide value, tax filings are charged on quarterly basis when we file taxes on behalf of your company. Th equation is:",
    equation: "Number of employees to report x ",
    plan: { monthly: "$2.00", yearly: "$0.99", type: "Quartely" },
    mdDelay: "0",
    smDelay: "0"
  },
];

export const links = [
  {
    id: 1,
    text: "Features",
    url: "#features",
  },
  {
    id: 2,
    text: "Pricing",
    url: "#pricing",
  },
  {
    id: 3,
    text: "Compare",
    url: "#compare",
  },
];

export const faq = [
  { 
    heading: "What forms of payment do you accept?", 
    details: "We accept all major card networks, wire transfer, ACH, and PayPal.", 
    }, 
    { 
    heading: "When will I be charged?", 
    details: "Our billing cycles are monthly. Typically, on the first day of each month we invoice and automatically charge your account’s primary payment method for the previous month’s usage. Quarterly tax filings are charged in the month we file for you.", },
    { 
    heading: "Am I charged when I enter my credit card or set up a payment method?", 
    details: "No. You will only be charged at the end of the billing cycle.Pre-authorization charge: When you add a card, we may send a preauthorization request to the issuing bank. This is to verify that the card being added has been issued by the bank and that they will authorize any charges in future. These temporary pre-authorizations are typically $1 but can vary in range and are immediately canceled by us. Depending on your bank, it might take a few days for the charge to clear from the card.", 
    },
    { 
    heading: "I got a 2 months free trial when I opened an account. How does that work?", 
    details: "Every new account gets 2 months for free to test our service, if at the end of the 2 months you love the services, you don’t have to do anything further. Enjoy! We worked very hard on it. If you rather not use our service, simple don’t, and you will not be charged. If you later decide we are better and cheaper, simple start using us again.", 
    },
    { 
    heading: "Can you extend my free trial?", 
    details: "No can do amigo! We too pay for third party services to keep this website running and to use the banking infrastructure for direct deposits. Do support us.",  
    },
    { 
    heading: "Can I have an account without adding a payment method?", 
    details: "Haha. Sure! But you cannot add employees without a payment method. Please don't bother contacting us for this, our hands are tied.", 
    },
    { 
    heading: "How do I remove my card from the account?", 
    details: "To remove a card, click the '...' menu of the card, then click Delete. In the Confirm Delete Card window that opens, click Delete to remove the card. You cannot remove a card if it is the default payment method for your account.", 
    },
    { 
    heading: "Can I prepay for my usage?", 
    details: "Yes, you can make pre-payments with PayPal. Pre-payments let you pay ahead of time for future usage. When your account balance is due, we apply pre-payments before we charge any payment method", 
    },
    { 
    heading: "Do you offer payroll in my state?", 
    details: "Yes, we offer payroll in all 50 states at the same price.", 
    },
    { 
    heading: "How do I get set up on Dojo?", 
    details: "You can easily get started today. Check out our guide to switching. If you’re setting up payroll for the first time, here’s everything you need to know.", 
    },
    { 
    heading: "Can I pay independent contractors?", 
    details: "Yes, you can pay salaried/hourly employees and contractors. We also automatically file and email them their W-2s or 1099s at the end of the year at no extra charge.", 
    },
    { 
    heading: "What is Automatic Payroll?", 
    details: "Dojo can run your payroll automatically. For salaried or hourly employees with fixed hours, all you have to do is decide on what day they should be paid, and we’ll do it automatically. We’ll even send you a reminder one day before, in case you want to make changes. Learn more.", 
    },
    { 
    heading: "Does Dojo support tip credits?", 
    details: "Yes, you can choose to have Dojo automatically adjust wages to comply with the FLSA Tip Credit and minimum wage adjustment requirement. Dojo can also help restaurants claim the FICA tip credit tax incentive: At the end of the year, we will supply a report with the details your accountant will need to claim this credit on your corporate tax returns.", 
    },
    { 
    heading: "How does Dojo handle garnishments and levies?", 
    details: "You can easily add garnishments and post-tax deductions in employee settings. We also automatically pay child-support garnishments for you in all states.", 
    },
    { 
    heading: "What is the next-day direct deposit?", 
    details: "Next-day direct deposit is a facility for our customers to run payroll by 4pm PT and get their team paid the next day at 5pm local time. Your employees can choose to expedite the payment with Real Time Payment and get their funds instantly.", 
    },
    {
    heading: "What is Real Time Payment?", 
    details: "Real Time Payment (RTP) is a facility for our customers  or their employees to expedite their  payments and get their fund in 30 minutes for $4", 
    },
    
    { 
    heading: "Does Dojo file W-2 and 1099 forms?", 
    details: "Yes, we file W-2s and 1099s on your behalf. Employees and contractors receive electronic copies of their forms as well. If you switch to Gusto in the middle of the year, we will file complete W-2s for the entire year. During your setup, we gather the necessary wage information to help ensure W-2s are accurate for the full year.", 
    },
    
    { 
    heading: "How will my employees get their paystubs?", 
    details: "On payday, we send your employees an email with a link to their full paystubs.", 
    },
    { 
    heading: "Can my employees get paid through direct deposit?", 
    details: "Yes, next- day direct deposit is included, along with digital paystubs, paperless onboarding, time tracking and more.", 
    },
    
    { 
    heading: "What’s included in employee accounts?", 
    details: "Employees get lifetime access to their Dojo account to see paystubs and W-2s. They can also track their hours worked, and update their personal info in their Dojo account. Learn more.", 
    },
    
    { 
    heading: "Does Dojo offer health benefits in my state?", 
    details: "Dojo does not offer any benefit account, we only help you automate the calculations. You are free to choose any provider you want.", 
    },
    
    { 
    heading: "Does Dojo offer time tracking software?", 
    details: "Yes. Customers have access to our time tracking solution that automatically syncs with payroll. Hourly and salaried non-exempt employees and contractors can track their hours for payroll. Learn more.", 
    },
    
    { 
    heading: "Can you help me switch payroll providers?", 
    details: "Yes! Our support team will import all of your data securely into Dojo. See all the ways to contact us at the bottom of the page.", 
    },
    
    { 
    heading: "Can I talk with a live person?", 
    details: "Yep! We are not a soulless corporation, yet.", 
    },
    
    
]

export const CartReducer = (state, action) => {

  const cart = {
    "payroll": {
      ...state,
      people: [...state.people, action.payload],
      isModalOpen: true,
      message: `Item is added`,
    },
    "NO_VALUE":{
      ...state,
      isModalOpen: true,
      message: "Please enter all values",
    },
    "CLOSE_MODAL":{
      ...state,
      isModalOpen: false,
    },
    "REMOVE":{
      people: state.people.filter((person) => 
          person.id !== action.payload)
    },

  };


  if(cart[action.type]){
    return cart[action.type]
  }
  else{
    return state
  }
}