import { gql } from '@apollo/client';

export const AUTH = gql`
    query Auth($email: String!, $uid: String!){
        auth(email: $email, uid: $uid){
            id
            username
            lastLogin
            userType
            isActive
            dateJoined
        }
    }
`;

// COMPANY QUERIES
export const COMPANY_INFO_Q = gql`
    query Company($email: String!, $uid: String!){
        company(email: $email, uid: $uid, eventType: "query"){
            firstName
            lastName
            email
            phone
            streetAddress
            city
            zipCode
            state
            country
            company
            professionalId
            taxSettings
            user{
                id
            }
        }
    }
`;

export const TAX_SETTINGS_Q = gql`
    query Company($email: String!, $uid: String!){
        company(email: $email, uid: $uid, eventType: "query"){
            taxSettings
            user{
                id
            }
        }
    }
`;


// EMPLOYEE QUERIES  
export const EMPLOYEES_Q = gql`
    query Employees($email: String!, $uid: String!, $eventType: String! ){
        employees(email: $email, uid: $uid, eventType: $eventType){
            firstName
            lastName
            email
            phone
            streetAddress
            city
            state
            zipCode
            country
            taxSettings
            slug
            nextPayDate
            hourlyRate
            salary
            payFrequency
            ssn
            user{
                id
                isActive
            }
        }
    }
`;



