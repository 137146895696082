import React, { createContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { confirmPath, resetPath} from "logged_out/components/Routing"
import { 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged, 
    getAuth, 
    signOut,
    sendEmailVerification,
    sendPasswordResetEmail,
    updatePassword,
    confirmPasswordReset,
    verifyPasswordResetCode,
    applyActionCode,
    updateProfile,
    // verifyBeforeUpdateEmail,
} from "firebase/auth";
import Cookies from 'js-cookie'




const tickCalculator = (ticks)=>{
    const date = new Date()
    if(!ticks){ticks = date.getTime()}
    const big = 621355968000000000n
    const offset = ((ticks * 10000) + big) - (date.getTimezoneOffset() * 600000000);
    console.log(offset);

    return offset
}

const {
    REACT_APP_FIREBASE_API_KEY,
    REACT_APP_FIREBASE_APP_ID,
    REACT_APP_FIREBASE_AUTH_DOMAIN,
    REACT_APP_FIREBASE_PROJECT_ID,
    REACT_APP_FIREBASE_STORAGE_BUCKET,
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
} = process.env

const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig)

let auth;

try {
    auth = getAuth(app)
} catch (error) {
    console.log(error);
}
export const authContext = createContext();

export const signUp = (email, password) =>{
    return createUserWithEmailAndPassword(auth, email, password)
}

export const updateInfo = (displayName, photo=null)=>{
    updateProfile(auth.currentUser, {
        displayName: displayName, photoURL: photo
    }).then(() => {
        return
    }).catch((error) => {
        return error
    });
}

export const verifyEmail = () =>{
    return sendEmailVerification(auth.currentUser, {url: `${window.location.origin}/${confirmPath}/`})
}

export const completeVerification = (oobCode) =>{
    return applyActionCode(auth, oobCode)
}

export const resetPasswordEmail = (email) =>{
    return sendPasswordResetEmail(auth, email, {url: `${window.location.origin}/${resetPath}/`})
}

export const verifyCode = (oobCode) =>{
    return verifyPasswordResetCode(auth, oobCode)
}

export const confirmReset = (oobCode, newPassword) =>{
    return confirmPasswordReset(auth, oobCode, newPassword)
}

export const changePassword = (newPassword) =>{
    return updatePassword(auth.currentUser, newPassword)
}

export const login = (email, password) =>{
    return signInWithEmailAndPassword(auth, email, password)
}


export const logout = ()=>{
    Cookies.remove('email')
    Cookies.remove('uid')
    Cookies.remove('emailVerified')
    return signOut(auth)
}

export const AuthProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        auth && onAuthStateChanged(auth, (user) => {
            if (user) {
              // https://firebase.google.com/docs/reference/js/firebase.User
                setCurrentUser(user);
                setLoading(false); 
                Cookies.set('email', user.email)
                Cookies.set('uid', user.uid)
                Cookies.set('emailVerified', user.emailVerified)
            } else {
              setCurrentUser(null);
              setLoading(false); 
              Cookies.remove('email')
              Cookies.remove('uid')
              Cookies.remove('emailVerified')
            }
        });
          
    }, [])

    const contextValues = { 
        currentUser,
        tickCalculator,
        logout,
    };

    return (
        <authContext.Provider value={contextValues}>
            {!loading && children}
        </authContext.Provider>
    )
}


