import React, { Fragment, useRef, useCallback, useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Drawer,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Tooltip,
  Box,
  withStyles,
  isWidthUp,
  withWidth,
} from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import MenuIcon from "@material-ui/icons/Menu";
import MessagePopperButton from "./MessagePopperButton";
import NavigationDrawer from "../../../shared/components/NavigationDrawer";
import Logo from "shared/components/Logo";
import AccountInformationArea from "../dashboard/AccountInformationArea";
import PersonIcon from '@material-ui/icons/Person';
import { mainContext } from "../Main";
import BuildIcon from '@material-ui/icons/Build';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';



const styles = (theme) => ({
  appBar: {
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  appBarToolbar: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  accountAvatar: {
    backgroundColor: theme.palette.secondary.main,
    height: 35,
    width: 35,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
  },
  drawerPaper: {
    height: "100%vh",
    whiteSpace: "nowrap",
    border: 0,
    width: theme.spacing(7),
    overflowX: "hidden",
    marginTop: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
    backgroundColor: theme.palette.common.black,
  },
  smBordered: {
    [theme.breakpoints.down("xs")]: {
      borderRadius: "50% !important",
    },
  },
  menuLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  iconListItem: {
    width: "auto",
    borderRadius: theme.shape.borderRadius,
    paddingTop: 11,
    paddingBottom: 11,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textPrimary: {
    color: theme.palette.primary.main,
  },
  mobileItemSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  brandText: {
    fontFamily: "'Baloo Bhaijaan', cursive",
    fontWeight: 400,
  },
  username: {
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
  },
  justifyCenter: {
    justifyContent: "center",
  },
  permanentDrawerListItem: {
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

function NavBar(props) {
  const { selectedTab, classes, width } = props;
  // Will be use to make website more accessible by screen readers
  const links = useRef([]);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const {mainState} = useContext(mainContext);
  const {fetchRandomMessages, logout, currentUser} = mainState

  let s3Url = JSON.parse(localStorage.getItem('s3Url'))
  
  const openMobileDrawer = useCallback(() => {
    setIsMobileOpen(true);
  }, [setIsMobileOpen]);

  const closeMobileDrawer = useCallback(() => {
    setIsMobileOpen(false);
  }, [setIsMobileOpen]);

  let history = useHistory()

  const handleLogout = async ()=>{
    await logout().then(() => {
      history.push("/")
    }).catch((error) => {
      console.log("an error occured while signing out");
    });
      
  }
  

  useEffect(() => {
    setMessages(fetchRandomMessages())
	}, [fetchRandomMessages])


  const menuItems = [
    {
      link: "/d/dashboard",
      name: "Dashboard",
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <AccountBalanceIcon
            className={
              selectedTab === "Dashboard" ? classes.textPrimary : "text-white"
            }
            fontSize="small"
          />
        ),
        mobile: <AccountBalanceIcon className="text-white" />,
      },
    },
    {
      link: "/d/company",
      name: "Company Profile",
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <PersonIcon
            className={
              selectedTab === "Company Profile" ? 
              classes.textPrimary : "text-white"
            }
            fontSize="small"
          />
        ),
        mobile: <PersonIcon className="text-white" />,
      },
    },
    // {
    //   link: "/d/subscription",
    //   name: "Subscription",
    //   onClick: closeMobileDrawer,
    //   icon: {
    //     desktop: (
    //       <AccountBalanceIcon
    //         className={
    //           selectedTab === "Subscription"
    //             ? classes.textPrimary
    //             : "text-white"
    //         }
    //         fontSize="small"
    //       />
    //     ),
    //     mobile: <AccountBalanceIcon className="text-white" />,
    //   },
    // },
    {
      link: "/d/settings",
      name: "Settings",
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <BuildIcon
            className={
              selectedTab === "Settings"
                ? classes.textPrimary
                : "text-white"
            }
            fontSize="small"
          />
        ),
        mobile: <BuildIcon className="text-white" />,
      },
    },
    {
      link: "/d/add-employee",
      name: "Add Employee",
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <PersonAddIcon
            className={
              selectedTab === "Add Employee"
                ? classes.textPrimary
                : "text-white"
            }
            fontSize="small"
          />
        ),
        mobile: <PersonAddIcon className="text-white" />,
      },
    },
    {
      link: "/clock",
      name: "Time Portal",
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <AccessAlarmIcon
            className={
              selectedTab === "Time Portal"
                ? classes.textPrimary
                : "text-white"
            }
            fontSize="small"
          />
        ),
        mobile: <AccessAlarmIcon className="text-white" />,
      },
    },
    {
      link: "",
      name: "Logout",
      onClick: handleLogout,
      icon: {
        desktop: (
          <PowerSettingsNewIcon className="text-white" fontSize="small" />
        ),
        mobile: <PowerSettingsNewIcon className="text-white" />,
      },
    },
  ];
  return (
    <Fragment>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar className={classes.appBarToolbar}>
          <Box display="flex" alignItems="center">
            <Hidden smUp>
              <Box mr={1}>
                <IconButton
                  aria-label="Open Navigation"
                  onClick={openMobileDrawer}
                  color="primary"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Hidden>
            <Hidden xsDown>
              <Link to='/d/dashboard' >
                <Logo size="3rem"/>
              </Link>
            </Hidden>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
          >
            <MessagePopperButton messages={messages} />
            <ListItem
              disableGutters
              className={classNames(classes.iconListItem, classes.smBordered)}
            >
              <Link to='/d/company' >
                <Avatar
                  alt="profile picture"
                  src={s3Url?.url || `${process.env.PUBLIC_URL}/assets/flag.gif` }
                  className={classNames(classes.accountAvatar)}
                />
              </Link>
              {isWidthUp("sm", width) && (
                <ListItemText
                  className={classes.username}
                  primary={
                    <Typography color="textPrimary">{currentUser ? currentUser?.displayName : "Display Name not set"}</Typography>
                  }
                />
              )}
            </ListItem>
          </Box>
          <AccountInformationArea
            isAccountActivated={ currentUser?.emailVerified }
          />
        </Toolbar>
      </AppBar>
      <Hidden xsDown>
        <Drawer //  both drawers can be combined into one for performance
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={false}
        >
          <List>
            {menuItems.map((element, index) => (
              <Link
                to={element.link}
                className={classes.menuLink}
                onClick={element.onClick}
                key={index}
                ref={(node) => {
                  links.current[index] = node;
                }}
              >
                <Tooltip
                  title={element.name}
                  placement="right"
                  key={element.name}
                >
                  <ListItem
                    selected={selectedTab === element.name}
                    button
                    divider={index !== menuItems.length - 1}
                    className={classes.permanentDrawerListItem}
                    onClick={() => {
                      links.current[index].click();
                    }}
                    aria-label={
                      element.name === "Logout"
                        ? "Logout"
                        : `Go to ${element.name}`
                    }
                  >
                    <ListItemIcon className={classes.justifyCenter}>
                      {element.icon.desktop}
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              </Link>
            ))}
          </List>
        </Drawer>
      </Hidden>
      <NavigationDrawer
        menuItems={menuItems.map((element) => ({
          link: element.link,
          name: element.name,
          icon: element.icon.mobile,
          onClick: element.onClick,
        }))}
        anchor="left"
        open={isMobileOpen}
        selectedItem={selectedTab}
        onClose={closeMobileDrawer}
      />
    </Fragment>
  );
}

NavBar.propTypes = {
  width: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  openAddBalanceDialog: PropTypes.func.isRequired,
};

export default withWidth()(withStyles(styles, { withTheme: true })(NavBar));
