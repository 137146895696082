import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Typography, withStyles, Card, Box, Button } from "@material-ui/core";
import classNames from "classnames";
// import Button from "./Button";

const styles = theme => ({
  card: {
    borderRadius: "0.5rem",
    fontSize: 16,
    boxShadow: theme.shadows[0],
    [theme.breakpoints.up("xs")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down("lg")]: {
      width: "auto",
    },
  },
  wrapper: {
    position: "relative",
    // backgroundColor: theme.palette.primary.main,
    // paddingBottom: theme.spacing(2),
  },
  container: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
  containerFix: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "none !important",
    },
  },
  
});


const PricingCard = (props) =>{
  const { classes, icon, plan, desc, equation, period, checkQuestionnaire } = props;


  return (
    <Fragment>
      <div className={classNames(classes.wrapper)}>
        <div className={classNames("container-fluid", classes.container)}>
          <Box display="flex" justifyContent="center" className="row">
            <Card
              className={classes.card}
              data-aos-delay="200"
              data-aos="zoom-in"
            >
              <div variant="h3" align="center" color="textPrimary">
                {icon}
              </div>
              <Typography variant="h3" align="center" color="textPrimary">
                {plan}{period}
              </Typography>
              <Typography variant="body1" color="textSecondary" style={{fontWeight: 'bold'}}>
                {desc}
              </Typography>
              <Typography variant="body1" color="textPrimary" style={{fontWeight: 'bold'}}>
                {equation}{plan}
              </Typography>
              <div style={{textAlign: 'center', alignItems: "center", marginTop: "2rem", justifyContent: "center", }} >
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={checkQuestionnaire}
                  fullWidth={false}
                >
                  2 Months Free Trial
                </Button>
              </div>
            </Card>
          </Box>
        </div>
      </div>
    </Fragment>
  );
}

PricingCard.propTypes = {
  classes: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  desc: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(PricingCard);
