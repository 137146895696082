
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const Logo = ({size}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Avatar
                alt="logo" 
                src={`${process.env.PUBLIC_URL}/assets/logo.svg`}
                // className={classes.large}
                style={{width: size, height: size}}
            />
        </div>
    )
}

export default Logo