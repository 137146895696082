import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Grid,
  Typography,
  Card,
  Button,
  Hidden,
  Box,
  withStyles,
  withWidth,
  isWidthUp,
} from "@material-ui/core";
import WaveBorder from "shared/components/WaveBorder";
import ZoomImage from "shared/components/ZoomImage";
import {FaHandPointRight} from 'react-icons/fa'

const styles = (theme) => ({
  extraLargeButtonLabel: {
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  extraLargeButton: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    // marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  card: {
    boxShadow: theme.shadows[4],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.up("xs")]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down("lg")]: {
      width: "auto",
    },
    
  },
  wrapper: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
    paddingBottom: theme.spacing(2),
  },
  image: {
    maxWidth: "100%",
    verticalAlign: "middle",
    borderRadius: theme.shape.borderRadius,
  },
  container: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  containerFix: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "none !important",
    },
  },
  waveBorder: {
    paddingTop: theme.spacing(4),
  },
  topline: {
    color: "#ff0066",
    fontSize: "1em",
    fontWeight: "700",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
  },
  "@keyframes animatePointers": {
    from: { transform: "translateX(-15px)" },
    to: { transform: "translateX(0)" }
  },
  animatePointers: { animation: "$animatePointers 0.5s alternate-reverse infinite " },
  "@keyframes moveDown": {
    from: { transform: "translateY(-100px)" },
    to: { transform: "translateY(0)", opacity: '1' }
  },
  moveDown: { animation: "$moveDown 2s ease-in-out forwards ", opacity: '0'},
  "@keyframes revealImage": {
    from: { clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)' },
    to: { clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' }
  },
  revealImage: { animation: "$revealImage 2.5s forwards " },
  "@keyframes moveUp": {
    from: { transform: "translateY(100px)" },
    to: { transform: "translateY(0)", opacity: '1' }
  },
  moveUp: { animation: "$moveUp 2s ease-in-out forwards ", opacity: '0' },

});

function Hero(props) {
  const { classes, theme, width, checkQuestionnaire } = props;
  
  return (
    <Fragment>
      <div className={classNames(classes.wrapper)}>
        <div className={classNames("container-fluid", classes.container)}>
          <Box display="flex" justifyContent="center" className={classNames('row')}>
            <Card
              className={classNames(classes.card)}
              data-aos-delay="200"
              data-aos="zoom-in"
            >
              <div >
                <Box justifyContent="space-between" className={classNames('row')}>
                  <Grid item xs={12} md={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      align={props.size <= 960 ? "center" : "left"}
                      height="100%"
                    >
                      <Box>
                        <Typography variant="h1" className={classes.topline}> 
                          {props.topline}
                        </Typography>
                      </Box>
                      {/* <Divider  /> */}
                      <Box mb={4}>
                        <Typography
                          variant={isWidthUp("lg", width) ? "h3" : "h4"}
                          className={classes.moveDown}
                        >
                          {props.headline}
                        </Typography>
                      </Box>
                      <div>
                        <Box mb={2}>
                          {props.descriptions.map((text, index)=>{
                              return (
                                <Typography
                                  variant={isWidthUp("lg", width) ? "h6" : "body1"}
                                  color="textSecondary"
                                  key={index}
                                  className={classes.moveDown}
                                >
                                  <FaHandPointRight className={classes.animatePointers} /> {text}
                                </Typography>
                              )
                            })}
                        </Box>
                        {props.size <= 960 ? (
                          <Typography align="center">
                            <Button
                              variant="contained"
                              color="secondary"
                              className={classNames(classes.moveUp)}
                              classes={{ label: classes.extraLargeButtonLabel }}
                              onClick={checkQuestionnaire}
                            >
                              {props.buttonLabel}
                            </Button>
                          </Typography>
                        ) : (
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            className={classNames(classes.extraLargeButton, classes.moveUp)}
                            classes={{ label: classes.extraLargeButtonLabel }}
                            onClick={checkQuestionnaire}
                          >
                            {props.buttonLabel}
                          </Button>
                        )}
                      </div>
                    </Box>
                  </Grid>
                  <Hidden smDown>
                    <Grid item md={6}>
                      <ZoomImage
                        src={props.imgage} 
                        alt={props.alt}
                        className={classNames(classes.image, classes.revealImage)}
                      />
                    </Grid>
                  </Hidden>
                </Box>
              </div>
            </Card>
          </Box>
        </div>
      </div>
      <WaveBorder
        upperColor={theme.palette.secondary.main}
        lowerColor="#FFFFFF"
        className={classes.waveBorder}
        animationNegativeDelay={2}
      />
    </Fragment>
  );
}

Hero.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.string,
  theme: PropTypes.object,
};

export default withWidth()(
  withStyles(styles, { withTheme: true })(Hero)
);
