import { memo, useCallback, useState, useEffect, Fragment, useContext, useReducer, createContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles, ListItemText, Button, Toolbar } from "@material-ui/core";
import Routing from "./Routing";
import NavBar from "./navigation/NavBar";
import persons from "../dummy_data/persons";
import LazyLoadAddBalanceDialog from "./subscription/LazyLoadAddBalanceDialog";
// import {S3_GET_URL} from 'logged_in/payrollApi/Mutations'
import { authContext } from "firebase/Config";
import { useQuery, useLazyQuery } from "@apollo/client";
import mainReducer from 'shared/components/Utils';
import { COMPANY_INFO_Q } from "logged_in/payrollApi/Queries";
import { useHistory } from "react-router-dom";

const styles = (theme) => ({
  main: {
    marginLeft: theme.spacing(9),
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
});


export const mainContext = createContext(null);

function Main(props) {
  const history = useHistory()
  const { classes } = props;
  const [selectedTab, setSelectedTab] = useState(null);
  const [isAddBalanceDialogOpen, setIsAddBalanceDialogOpen] = useState(false);
  const [size, setSize] = useState(window.innerWidth)

	const fetchRandomStatistics = useCallback(() => {
		const statistics = { profit: [], views: [] };
		const iterations = 300;
		const oneYearSeconds = 60 * 60 * 24 * 365;
		let curProfit = Math.round(3000 + Math.random() * 1000);
		let curViews = Math.round(3000 + Math.random() * 1000);
		let curUnix = Math.round(new Date().getTime() / 1000) - oneYearSeconds;
		for (let i = 0; i < iterations; i += 1) {
		curUnix += Math.round(oneYearSeconds / iterations);
		curProfit += Math.round((Math.random() * 2 - 1) * 10);
		curViews += Math.round((Math.random() * 2 - 1) * 10);
		statistics.profit.push({
			value: curProfit,
			timestamp: curUnix,
		});
		statistics.views.push({
			value: curViews,
			timestamp: curUnix,
		});
		}

		return statistics
	}, []);

	const fetchPeople = useCallback(() => {
		const targets = [];

		for (let i = 0; i < 35; i += 1) {
      const randomPerson = persons[Math.floor(Math.random() * persons.length)];
      const target = {
        id: i,
        number1: Math.floor(Math.random() * 251),
        number2: Math.floor(Math.random() * 251),
        number3: Math.floor(Math.random() * 251),
        number4: Math.floor(Math.random() * 251),
        name: randomPerson.name,
        profilePicUrl: randomPerson.src,
        isActivated: Math.round(Math.random()) ? true : false,
      };
      targets.push(target);
		}

		return targets
	}, []);

  const openAddBalanceDialog = useCallback(() => {
    setIsAddBalanceDialogOpen(true);
  }, [setIsAddBalanceDialogOpen]);

  const closeAddBalanceDialog = useCallback(() => {
    setIsAddBalanceDialogOpen(false);
  }, [setIsAddBalanceDialogOpen]);

  const onPaymentSuccess = useCallback(() => {
    mainDispatch({type: 'notification', level: 'info', message: 'Your balance has been updated'})
    setIsAddBalanceDialogOpen(false);
  }, [setIsAddBalanceDialogOpen]);

  const fetchRandomTransactions = useCallback(() => {
    const transactions = [];
    const iterations = 32;
    const oneMonthSeconds = Math.round(60 * 60 * 24 * 30.5);
    const transactionTemplates = [
      {
        description: "Starter subscription",
        isSubscription: true,
        balanceChange: -1499,
      },
      {
        description: "Premium subscription",
        isSubscription: true,
        balanceChange: -2999,
      },
      {
        description: "Business subscription",
        isSubscription: true,
        balanceChange: -4999,
      },
      {
        description: "Tycoon subscription",
        isSubscription: true,
        balanceChange: -9999,
      },
      {
        description: "Added funds",
        isSubscription: false,
        balanceChange: 2000,
      },
      {
        description: "Added funds",
        isSubscription: false,
        balanceChange: 5000,
      },
    ];
    let curUnix = Math.round(
      new Date().getTime() / 1000 - iterations * oneMonthSeconds
    );
    for (let i = 0; i < iterations; i += 1) {
      const randomTransactionTemplate =
        transactionTemplates[
          Math.floor(Math.random() * transactionTemplates.length)
        ];
      const transaction = {
        id: i,
        description: randomTransactionTemplate.description,
        balanceChange: randomTransactionTemplate.balanceChange,
        paidUntil: curUnix + oneMonthSeconds,
        timestamp: curUnix,
      };
      curUnix += oneMonthSeconds;
      transactions.push(transaction);
    }
    transactions.reverse();
    return transactions
  }, []);


  const fetchRandomMessages = useCallback(() => {
    mainDispatch({type: 'shuffle', array: persons})
    const messages = [];
    const iterations = persons.length;
    const oneDaySeconds = 60 * 60 * 24;
    let curUnix = Math.round(
      new Date().getTime() / 1000 - iterations * oneDaySeconds
    );
    for (let i = 0; i < iterations; i += 1) {
      const person = persons[i];
      const message = {
        id: i,
        src: person.src,
        date: curUnix,
        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed.",
      };
      curUnix += oneDaySeconds;
      messages.push(message);
    }
    messages.reverse();
    return messages
  }, []);


  const fetchRandomPosts = useCallback(() => {
    mainDispatch({type: 'shuffle', array: persons})
    const posts = [];
    const iterations = persons.length;
    const oneDaySeconds = 60 * 60 * 24;
    let curUnix = Math.round(
      new Date().getTime() / 1000 - iterations * oneDaySeconds
    );
    for (let i = 0; i < iterations; i += 1) {
      const person = persons[i];
      const post = {
        id: i,
        src: person.src,
        timestamp: curUnix,
        name: person.name,
      };
      curUnix += oneDaySeconds;
      posts.push(post);
    }
    posts.reverse();
    return posts
  }, []);

  const { currentUser, logout } = useContext(authContext)
  // const [mutate] = useMutation(S3_GET_URL)

  // const getS3Url = useCallback( async () => {
  //   let s3Url = JSON.parse(localStorage.getItem('s3Url'))
  //   if(!s3Url || new Date(s3Url.expiry) < new Date() ){
  //     try {
  //       const get = await mutate({ variables: {email: currentUser.email}})
  //       if(get.data?.s3GetUrl.success && get.data.s3GetUrl.payload){
  //         const {payload} = get.data.s3GetUrl
  //         await import("shared/components/Utils").then(utils => {
  //           localStorage.setItem('s3Url', JSON.stringify({
  //             'url': payload,
  //             'expiry': utils.futureDate(6),
  //           }))
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // },[mutate, currentUser]);

function ToolbarButton({ text, heading, link, }) {
  
  return (
    <Toolbar style={{justifyContent: "space-between"}}>
      <ListItemText primary={heading} />
      <Button
        variant="contained"
        color="secondary"
        onClick={()=>history.push(`${link}`)}
        disableElevation
      >
        {text}
      </Button>
    </Toolbar>
  );
}


  // eslint-disable-next-line
  const { data, refetch } = useQuery(COMPANY_INFO_Q, { 
    variables:{ email: currentUser?.email, uid: currentUser?.uid }
  })  
  
  const [mainState, mainDispatch] = useReducer(mainReducer, {
    useQuery, useLazyQuery,
    currentUser, logout, mainReducer,
    COMPANY_INFO_Q,
    fetchPeople, fetchRandomPosts, setSelectedTab, ToolbarButton,
    fetchRandomMessages, fetchRandomStatistics, fetchRandomTransactions,
    variables:{ email: currentUser?.email, uid: currentUser?.uid },
  });

  useEffect(() => {
    
    data?.company && mainDispatch({type: "companyId", id: +data.company[0].user.id}) // the "+" convert str to int
    !currentUser && history.push("/")
		const checkSize = () => {
			setSize(window.innerWidth);
		};
		window.addEventListener("resize", checkSize);
    Promise.all([
      // getS3Url(),
    ])
    return () => {
			window.removeEventListener("resize", checkSize);
		};
  }, [
    data,
    currentUser,
    history,
    // getS3Url,
  ]);

  return (
    <Fragment>
      <mainContext.Provider value={{mainState, mainDispatch }}>
        <LazyLoadAddBalanceDialog
          open={isAddBalanceDialogOpen}
          onClose={closeAddBalanceDialog}
          onSuccess={onPaymentSuccess}
        />
        <NavBar
          selectedTab={selectedTab}
          openAddBalanceDialog={openAddBalanceDialog}
        />
        <main className={classNames(classes.main)}>
          <Routing
            openAddBalanceDialog={openAddBalanceDialog}
            size={size}
          />
        </main>
      </mainContext.Provider>
    </Fragment>
  );
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(memo(Main));
