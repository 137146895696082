import { useState, useEffect, useReducer, useContext} from 'react';
import QRCode from 'qrcode';
import { Button, Grid, makeStyles, Paper } from '@material-ui/core';
import { SelectField } from 'logged_in/components/companyForms/Fields';
import { Form, Formik } from 'formik';
import { EMPLOYEES_Q } from 'logged_in/payrollApi/Queries';
import ButtonCircularProgress from "shared/components/ButtonCircularProgress";
import { useMutation, useQuery } from '@apollo/client';
import { authContext } from 'firebase/Config';
import mainReducer from 'shared/components/Utils';
import * as Yup from "yup";
import { CLOCK_IN } from 'logged_in/payrollApi/Mutations';




export const QRCoder = () => {
  const [src, setSrc] = useState('')
  const uniqueId = []
  for (let i = 1; i < 8; i++) uniqueId.push(Math.random().toString(35).substring(2, 35))
    useEffect(() => {
        QRCode.toDataURL(`${new Date()}_uniqueId`)
        .then(setSrc)
        .catch(err => {
          console.error(err)
        })
    }, [])
    
  return (
    <div>
        <img src={src} alt='qrcode'/>
    </div>
  )
}


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperLeft: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: "#5f4def",
    height: "100vh",
    paddingTop: "10em",
  },
}));


const reducer = (state, action) => {
	switch (action.type) {
		case 'employees':
      const data = action.data.reduce((acc, data)=>{
        const wrangled = mainReducer('state',{type: 'wrangle data', data})
        wrangled.nextPayDate = new Date(wrangled.nextPayDate)
        .toLocaleString('en-us',{month:'short', day:'numeric'})
        acc.push(wrangled)
        return acc
      },[])

      let employees = []
      data.forEach((elm, index) =>{
        elm.isActive && employees.push({key: index, value: `${elm.firstName} ${elm.lastName}`})
      })
      employees.sort((a, b) => a.value > b.value ? 1 : -1)
			return {employees, data};

		default:
			return state;
	}
}

export const TimePortal = ({selectTimePortal}) => {
  const classes = useStyles();


	const schema = Yup.object().shape({
		"index": Yup.string().required("Please select your name."),
	})

  const [state, dispatch] = useReducer(reducer, {});

  const { currentUser } = useContext(authContext)

	const { data } = useQuery(EMPLOYEES_Q, { 
		variables:{ email: currentUser?.email, uid: currentUser?.uid, eventType: "query"},
	})

  const [mutate] = useMutation(CLOCK_IN);

  useEffect(() => {
    selectTimePortal()
    data?.employees &&  dispatch({
			type: 'employees', 
			data: data.employees,
			mainReducer,
		})
	}, [data, selectTimePortal])

  const onSubmit = async (values)=>{
    try {
      const { id, firstName } = state.data[values.index]
			// dispatch({type: 'info', values})
			const toReturn = await mutate({variables: {
				email: currentUser?.email, 
        uid: currentUser?.uid, 
        eventType: "clock_in_out",
				employee: +id,
			}})
      const { clockOut } = toReturn.data.clockIn.toReturn
      clockOut && mainReducer("state",{type: 'notification', level: 'success', message: `Good job ${firstName}! You have now clocked out.`})
      !clockOut && mainReducer("state",{type: 'notification', level: 'success', message: `Welcome ${firstName}! Your clocked has started.`})
		} catch (error) {
      mainReducer("state",{type: 'notification', level: 'error', message: `${error.message}`})
		}
  }
    
  return (
    <>
      <Paper className={classes.paperLeft}>
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            width: "100vw",
            height: "100%",
            left: "50%",
            top: "50%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            // zIndex: "-1"
            opacity: 0.2
          }}
        >
          <source src={`${process.env.PUBLIC_URL}/assets/clockGif3.mp4`} />
        </video>
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              { state?.employees?.length ?(
                <>
                  <h2 style={{color: "white"}}>Scan the code to clock in and out</h2>
                  <QRCoder />
                  <h2 style={{color: "white"}}>OR</h2>
                  <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                    enableReinitialize
                  >
                    {({ isSubmitting, setFieldValue,  }) => (
                      <Form id="timePortal-form">
                        <SelectField 
                          label="Selecet Your Name" 
                          name="index"
                          options={state.employees} 
                          minWidth={250}
                          style={{backgroundColor: "#fff", marginBottom: "1em"}}
                          onChange={(e)=>{
                            setFieldValue("index", e.target.value)
                          }}
                        />
                        <Button 
                          type='submit'
                          variant='contained'
                          style={{backgroundColor: "white", color: "black"}}
                        >
                          {isSubmitting ? <ButtonCircularProgress /> : "Submit"}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </>
              ):(
                <h2 style={{color: "#fff", align: "center", fontWeight: 700}}>
                  Must be logged in as a company to use this service
                </h2>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
            <p></p>
            </Grid>
        </Grid>
      </Paper>
    </>
  )
}
