import React, { memo } from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import {PropsRoute} from "../../shared/components/PropsRoute";
import Home from "./home/Home";
import Blog from "./blog/Blog";
import BlogPost from "./blog/BlogPost";
import useLocationBlocker from "../../shared/functions/useLocationBlocker";
import ConfirmationPage from "firebase/ConfirmationPage";
import ResetPassword from "firebase/ResetPassword";
import {TimePortal} from "logged_in/components/dashboard/TimePortal";


export const authPath = "auth=B6132A42124CC790F9EFD0FCFF4C3B653AA0DF4B9CEAA2F8C4E72E1D61336D3794301116C092B"
export const confirmPath = "confirm=66251AE6324F670BF988465E49B9CEAA2F8C4E72E1D61336D3794301116C092B"
export const resetPath = "reset=B7FFC27E0A6F559E1F63A927B6132A42124CC790F9EFD0FCFF4C3B653AA0DF4B"

function Routing(props) {
  const { 
    blogPosts, 
    selectBlog, selectHome, 
    checkQuestionnaire,
    selectResetPassword, 
    selectConfirmationPage,
    size,
    selectTimePortal,

  } = props;

  useLocationBlocker();


  return (
    <Switch>
      {blogPosts.map((post) => (
        <PropsRoute
          path={post.url}
          component={BlogPost}
          title={post.title}
          key={post.title}
          src={post.src}
          date={post.date}
          content={post.content}
          otherArticles={blogPosts.filter(
            (blogPost) => blogPost.id !== post.id
          )}
        />
      ))}
      <PropsRoute
        exact
        path="/blog"
        component={Blog}
        selectBlog={selectBlog}
        blogPosts={blogPosts}
      />
      <PropsRoute
        exact
        path={`/${confirmPath}`}
        component={ConfirmationPage}
        selectConfirmationPage={selectConfirmationPage}
      />
      <PropsRoute
        exact
        path={`/${resetPath}`}
        component={ResetPassword}
        selectResetPassword={selectResetPassword}
      />
      <PropsRoute
        exact
        path="/clock"
        component={TimePortal}
        selectTimePortal={selectTimePortal}
      />
      <PropsRoute 
        path="/" 
        component={Home} 
        selectHome={selectHome}
        checkQuestionnaire={checkQuestionnaire}
        size={size}
      />
    </Switch>
  );
}

Routing.propTypes = {
  blogposts: PropTypes.arrayOf(PropTypes.object),
  selectHome: PropTypes.func.isRequired,
  selectBlog: PropTypes.func.isRequired,
};

export default memo(Routing);
