import { gql } from '@apollo/client';



export const EMPLOYEE_INFO_M = gql`
	mutation EMPLOYEE_INFO(
		$eventType: String!
		$employeeEmail: String!
		$company: ID!
		$uid: ID!
		$firstName: String!
		$lastName: String!
		$email: String!
		$phone: String!
		$streetAddress: String!
		$city: String!
		$state: String!
		$zipCode: String!
		$country: String!
	) {
		employeeInfo(
			eventType: $eventType
			employeeEmail: $employeeEmail
			company:$company
			personalInfo:{
				firstName: $firstName
				lastName: $lastName
				email: $email
				phone: $phone
				streetAddress: $streetAddress
				city: $city
				state: $state
				zipCode: $zipCode
				country: $country
				userType: "employee"
				uid: $uid
			}
		) {
			toReturn{
				firstName
				email
				phone
				user{
					id
				}
			}
		}
	}
`;

export const EMPLOYEE_PAYROLL_M = gql`
	mutation EmployeePayroll(
		$email: String!
		$uid: ID!
		$employee: ID!
		$timeZone: String!
		$payFrequency: String!
		$ssn: String!
		$nextPayDate: String!
		$taxFilingStatus: String!
		$w4Step2: Boolean!
		$w4Step3: Float!
		$w4Step4a: Float!
		$w4Step4b: Float!
		$w4Step4c: Float!
		$hourlyRate: Float
		$salary: Float

	){
		employeePayroll( 
			eventType: "employee_payroll"
			employee: $employee
			email: $email
			uid: $uid
			payFrequency: $payFrequency
			ssn: $ssn
			nextPayDate: $nextPayDate
			hourlyRate: $hourlyRate
			salary: $salary
			taxSettings: {
				timeZone: $timeZone
				taxFilingStatus: $taxFilingStatus
				w4Step2: $w4Step2
				w4Step3: $w4Step3
				w4Step4a: $w4Step4a
				w4Step4b: $w4Step4b
				w4Step4c: $w4Step4c
			}
		){
			toReturn{
				taxSettings
			}
		}
	}
`;

export const EMPLOYEE_BONUS_M = gql`
	mutation EmployeeBonus(
		$email: String!
		$uid: ID!
		$employee: ID!
		$name0: String
		$name1: String
		$name2: String
		$name3: String
		$name4: String
		$name5: String
		$name6: String
		$name7: String
		$name8: String
		$name9: String
		$rate0: Float
		$rate1: Float
		$rate2: Float
		$rate3: Float
		$rate4: Float
		$rate5: Float
		$rate6: Float
		$rate7: Float
		$rate8: Float
		$rate9: Float
		$apply_to0: String
		$apply_to1: String
		$apply_to2: String
		$apply_to3: String
		$apply_to4: String
		$apply_to5: String
		$apply_to6: String
		$apply_to7: String
		$apply_to8: String
		$apply_to9: String

	){
		employeeBonus( 
			eventType: "bonus"
			email: $email
			uid: $uid
			employee: $employee
			bonus:[
				{name: $name0, rate: $rate0, applyTo: $apply_to0}
				{name: $name1, rate: $rate1, applyTo: $apply_to1}
				{name: $name2, rate: $rate2, applyTo: $apply_to2}
				{name: $name3, rate: $rate3, applyTo: $apply_to3}
				{name: $name4, rate: $rate4, applyTo: $apply_to4}
				{name: $name5, rate: $rate5, applyTo: $apply_to5}
				{name: $name6, rate: $rate6, applyTo: $apply_to6}
				{name: $name7, rate: $rate7, applyTo: $apply_to7}
				{name: $name8, rate: $rate8, applyTo: $apply_to8}
				{name: $name9, rate: $rate9, applyTo: $apply_to9}
			]
		){
			toReturn{
				taxSettings
			}
		}
	}
`;

export const EMPLOYEE_PRE_TAX_M = gql`
	mutation EmployeePreTax(
		$email: String!
		$uid: ID!
		$employee: ID!
		$name0: String
		$name1: String
		$name2: String
		$name3: String
		$name4: String
		$name5: String
		$name6: String
		$name7: String
		$name8: String
		$name9: String
		$rate0: Float
		$rate1: Float
		$rate2: Float
		$rate3: Float
		$rate4: Float
		$rate5: Float
		$rate6: Float
		$rate7: Float
		$rate8: Float
		$rate9: Float
		$apply_to0: String
		$apply_to1: String
		$apply_to2: String
		$apply_to3: String
		$apply_to4: String
		$apply_to5: String
		$apply_to6: String
		$apply_to7: String
		$apply_to8: String
		$apply_to9: String

	){
		employeePreTax( 
			eventType: "pre_tax"
			email: $email
			uid: $uid
			employee: $employee
			preTax:[
				{name: $name0, rate: $rate0, applyTo: $apply_to0}
				{name: $name1, rate: $rate1, applyTo: $apply_to1}
				{name: $name2, rate: $rate2, applyTo: $apply_to2}
				{name: $name3, rate: $rate3, applyTo: $apply_to3}
				{name: $name4, rate: $rate4, applyTo: $apply_to4}
				{name: $name5, rate: $rate5, applyTo: $apply_to5}
				{name: $name6, rate: $rate6, applyTo: $apply_to6}
				{name: $name7, rate: $rate7, applyTo: $apply_to7}
				{name: $name8, rate: $rate8, applyTo: $apply_to8}
				{name: $name9, rate: $rate9, applyTo: $apply_to9}
			]
		){
			toReturn{
				taxSettings
			}
		}
	}
`;

export const EMPLOYEE_POST_TAX_M = gql`
	mutation EmployeePostTax(
		$email: String!
		$uid: ID!
		$employee: ID!
		$name0: String
		$name1: String
		$name2: String
		$name3: String
		$name4: String
		$name5: String
		$name6: String
		$name7: String
		$name8: String
		$name9: String
		$rate0: Float
		$rate1: Float
		$rate2: Float
		$rate3: Float
		$rate4: Float
		$rate5: Float
		$rate6: Float
		$rate7: Float
		$rate8: Float
		$rate9: Float
		$apply_to0: String
		$apply_to1: String
		$apply_to2: String
		$apply_to3: String
		$apply_to4: String
		$apply_to5: String
		$apply_to6: String
		$apply_to7: String
		$apply_to8: String
		$apply_to9: String

	){
		employeePostTax( 
			eventType: "post_tax"
			email: $email
			uid: $uid
			employee: $employee
			postTax:[
				{name: $name0, rate: $rate0, applyTo: $apply_to0}
				{name: $name1, rate: $rate1, applyTo: $apply_to1}
				{name: $name2, rate: $rate2, applyTo: $apply_to2}
				{name: $name3, rate: $rate3, applyTo: $apply_to3}
				{name: $name4, rate: $rate4, applyTo: $apply_to4}
				{name: $name5, rate: $rate5, applyTo: $apply_to5}
				{name: $name6, rate: $rate6, applyTo: $apply_to6}
				{name: $name7, rate: $rate7, applyTo: $apply_to7}
				{name: $name8, rate: $rate8, applyTo: $apply_to8}
				{name: $name9, rate: $rate9, applyTo: $apply_to9}
			]
		){
			toReturn{
				taxSettings
			}
		}
	}
`;


export const EMPLOYEE_IS_ACTIVE = gql`
	mutation EmployeeIsActive(
		$email: String!
		$uid: ID!
		$employee: ID!
	){
		employeeIsActive( 
			eventType: "is_active"
			email: $email
			uid: $uid
			employee: $employee
		){
			toReturn{
				isActive
			}
		}
	}
`;

export const DELETE_EMPLOYEE = gql`
	mutation DeleteEmployee(
		$email: String!
		$uid: ID!
		$employee: ID!
	){
		deleteEmployee( 
			eventType: "is_active"
			email: $email
			uid: $uid
			employee: $employee
		){
			toReturn{
				firstName
			}
		}
	}
`;

export const S3_POST_URL = gql`
    mutation PostImageUrl($fileName: String!, $email: String!) {
        s3PostUrl(fileName: $fileName, email: $email) {
            success
            payload
        }
    }
`;

export const S3_GET_URL = gql`
    mutation GetImageUrl($email: String!) {
        s3GetUrl(email: $email) {
            success
            payload
        }
    }
`;

export const CART = gql`
	mutation Cart(
		$cartUid: ID!, 
		$countryCode: String!,
		$state: String,
		$city: String,
		$IPv4: String,
		$payroll: String,
		$how: String,
		$setting: String,
		$workers: String,
		$automate: String,
		$healthcare: String,
	){
		cartChoice(
		cart:[
			{name: "countryCode", strValue: $countryCode}
			{name: "state", strValue: $state}
			{name: "city", strValue: $city}
			{name: "IPv4", strValue: $IPv4}
			{name: "payroll", strValue: $payroll}
			{name: "how", strValue: $how}
			{name: "setting", strValue: $setting}
			{name: "workers", strValue: $workers}
			{name: "automate", strValue: $automate}
			{name: "healthcare", strValue: $healthcare}
		]
		cartUid: $cartUid
		){
			cart{
				cart
				cartUid
			}
		}
	}
`;

export const PRE_TAX_DEDUCTIONS_M = gql`
	mutation PreTaxDeductions(
		$email: String!
		$uid: ID!
		
		$name0: String
		$name1: String
		$name2: String
		$name3: String
		$name4: String
		$name5: String
		$name6: String
		$name7: String
		$name8: String
		$name9: String
		$rate0: Float
		$rate1: Float
		$rate2: Float
		$rate3: Float
		$rate4: Float
		$rate5: Float
		$rate6: Float
		$rate7: Float
		$rate8: Float
		$rate9: Float
		$apply_to0: String
		$apply_to1: String
		$apply_to2: String
		$apply_to3: String
		$apply_to4: String
		$apply_to5: String
		$apply_to6: String
		$apply_to7: String
		$apply_to8: String
		$apply_to9: String

	){
		preTaxDeductions( 
			eventType: "pre_tax"
			email: $email
			uid: $uid
			preTax:[
				{name: $name0, rate: $rate0, applyTo: $apply_to0}
				{name: $name1, rate: $rate1, applyTo: $apply_to1}
				{name: $name2, rate: $rate2, applyTo: $apply_to2}
				{name: $name3, rate: $rate3, applyTo: $apply_to3}
				{name: $name4, rate: $rate4, applyTo: $apply_to4}
				{name: $name5, rate: $rate5, applyTo: $apply_to5}
				{name: $name6, rate: $rate6, applyTo: $apply_to6}
				{name: $name7, rate: $rate7, applyTo: $apply_to7}
				{name: $name8, rate: $rate8, applyTo: $apply_to8}
				{name: $name9, rate: $rate9, applyTo: $apply_to9}
			]
		){
			toReturn{
				taxSettings
			}
		}
	}
`;

export const POST_TAX_DEDUCTIONS_M = gql`
	mutation PostTaxDeductions(
		$email: String!
		$uid: ID!

		$name0: String
		$name1: String
		$name2: String
		$name3: String
		$name4: String
		$name5: String
		$name6: String
		$name7: String
		$name8: String
		$name9: String
		$rate0: Float
		$rate1: Float
		$rate2: Float
		$rate3: Float
		$rate4: Float
		$rate5: Float
		$rate6: Float
		$rate7: Float
		$rate8: Float
		$rate9: Float
		$apply_to0: String
		$apply_to1: String
		$apply_to2: String
		$apply_to3: String
		$apply_to4: String
		$apply_to5: String
		$apply_to6: String
		$apply_to7: String
		$apply_to8: String
		$apply_to9: String
	){
		postTaxDeductions( 
			eventType: "post_tax"
			email: $email
			uid: $uid
			postTax:[
				{name: $name0, rate: $rate0, applyTo: $apply_to0}
				{name: $name1, rate: $rate1, applyTo: $apply_to1}
				{name: $name2, rate: $rate2, applyTo: $apply_to2}
				{name: $name3, rate: $rate3, applyTo: $apply_to3}
				{name: $name4, rate: $rate4, applyTo: $apply_to4}
				{name: $name5, rate: $rate5, applyTo: $apply_to5}
				{name: $name6, rate: $rate6, applyTo: $apply_to6}
				{name: $name7, rate: $rate7, applyTo: $apply_to7}
				{name: $name8, rate: $rate8, applyTo: $apply_to8}
				{name: $name9, rate: $rate9, applyTo: $apply_to9}
			]
		){
			toReturn{
				taxSettings
			}
		}
	}
`;

export const BONUS_M = gql`
	mutation Bonus(
		$email: String!
		$uid: ID!

		$name0: String
		$name1: String
		$name2: String
		$name3: String
		$name4: String
		$name5: String
		$rate0: Float
		$rate1: Float
		$rate2: Float
		$rate3: Float
		$rate4: Float
		$rate5: Float
		$apply_to0: String
		$apply_to1: String
		$apply_to2: String
		$apply_to3: String
		$apply_to4: String
		$apply_to5: String
	){
		bonus( 
			eventType: "bonus"
			email: $email
			uid: $uid
			bonus:[
				{name: $name0, rate: $rate0, applyTo: $apply_to0}
				{name: $name1, rate: $rate1, applyTo: $apply_to1}
				{name: $name2, rate: $rate2, applyTo: $apply_to2}
				{name: $name3, rate: $rate3, applyTo: $apply_to3}
				{name: $name4, rate: $rate4, applyTo: $apply_to4}
				{name: $name5, rate: $rate5, applyTo: $apply_to5}
			]
		){
			toReturn{
				taxSettings
			}
		}
	}
`;

export const COMPANY_GENERAL_SETTINGS = gql`
	mutation CompanyGeneralSettings(
		$firstName: String!,
		$lastName: String!,
		$email: String!,
		$phone: String!,
		$streetAddress: String!,
		$city: String!,
		$state: String!,
		$country: String!,
		$zipCode: String!,
		$userType: String!,
		$uid: ID!,
		$company: String!,
		$professionalId: Int!,
		$fileTaxForYou: Boolean!,
		$ein: String,
		$stateTaxNumber: String,
		$eventType: String!,

	) {
		companyGeneralSettings(
		personalInfo:{
			firstName: $firstName
			lastName: $lastName
			email: $email
			phone: $phone
			streetAddress: $streetAddress
			city: $city
			state: $state
			country: $country
			zipCode: $zipCode
			userType: $userType
			uid: $uid
		}
		company: $company
		eventType: $eventType
		professionalId: $professionalId
		taxSettings:{
		fileTaxForYou: $fileTaxForYou
		ein: $ein
		stateTaxNumber: $stateTaxNumber
	}
	)	{
		toReturn{
				firstName
				lastName
				email
				phone
				streetAddress
				city
				state
				zipCode
				country
				taxSettings
				user{
					id
				}
			}
		}
	}
`;


export const CLOCK_IN = gql`
	mutation ClockIn ($employee: ID!, $uid: ID!, $email: String!, $eventType: String!)  {
		clockIn(
			employee: $employee
			uid: $uid
			email: $email
			eventType: $eventType
		
		) {
			toReturn{
				clockOut 
			}
		}
	}
`;






