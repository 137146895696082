import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, isWidthUp, withWidth, FormGroup, FormControlLabel } from "@material-ui/core";
import PricingCard from "./PricingCard";
import {pricing} from './Data';
import SwitchButton from "shared/components/SwitchButton";



const calculateSpacing = (width) =>{
  if (isWidthUp("lg", width)) {
    return 5;
  }
  if (isWidthUp("md", width)) {
    return 4;
  }
  if (isWidthUp("sm", width)) {
    return 3;
  }
  return 2;
}


const PricingSection = (props) =>{
  const { width, checkQuestionnaire } = props;
  const [yearlyPricing, setYearlyPricing] = React.useState({checked:false});

  const handleYearPricingSwitch = (event) => {
    setYearlyPricing({ ...yearlyPricing, [event.target.name]: event.target.checked });
  };

  return (
    <div style={{ backgroundColor: "#fff", marginBottom: "2rem", marginTop: "2rem"}} id="pricing">
      <div className="container-fluid lg-p-top" >
        <Typography variant="h3" align="center" className="lg-mg-bottom">
          Pricing
          <FormGroup>
            <FormControlLabel  
              labelPlacement="bottom"
              control={<SwitchButton checked={yearlyPricing.checked} onChange={handleYearPricingSwitch} name="checked" />}
              label="Yearly (33% off)"
            />
          </FormGroup>
        </Typography>
        <div className="container-fluid">
          <Grid container spacing={calculateSpacing(width)}>
            {pricing.map(element => (
              <Grid
                item
                xs={12}
                md={6}
                data-aos="zoom-in-up"
                data-aos-delay={
                  isWidthUp("md", width) ? element.mdDelay : element.smDelay
                }
                key={element.id}
              >
                <PricingCard
                  icon={element.icon}
                  plan={
                    <span>
                      { yearlyPricing.checked ? element.plan.yearly : element.plan.monthly }
                    </span>
                  }
                  period={
                    <span>
                      <Typography display="inline" > { element.plan.type }</Typography>
                    </span>
                  }
                  price={element.price}
                  frequency={element.frequency}
                  equation={element.equation}
                  desc={element.desc}
                  checkQuestionnaire={checkQuestionnaire}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}

PricingSection.propTypes = {
  width: PropTypes.string.isRequired
};

export default withWidth()(PricingSection);
